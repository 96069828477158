import { type DonationAmount } from '@eo/graphql-types'
import { defineStore } from 'pinia'
import { DonationFormStep } from 'types/donationForm'
import { useOrderStore } from './orderStore'

export const useDonationStore = defineStore('donationForm', () => {
  const orderStore = useOrderStore()
  const AVAILABLE_STEPS_CONST = [
    {
      name: 'recurrance',
      isValid: true,
      hasNextButton: true,
      hasBackButton: false,
    },
    {
      name: 'amount',
      isValid: true,
      hasNextButton: true,
      hasBackButton: true,
    },
    {
      name: 'paymentMethod',
      isValid: false,
      hasNextButton: false,
      hasBackButton: true,
    },
    // Shortened contact
    {
      name: 'minimalContact',
      isValid: false,
      hasNextButton: true,
      hasBackButton: true,
    },
    // Regular contact
    {
      name: 'name',
      isValid: false,
      hasNextButton: true,
      hasBackButton: true,
    },
    // Ideal/incasso
    {
      name: 'address',
      isValid: false,
      hasNextButton: true,
      hasBackButton: true,
    },
    {
      name: 'account',
      isValid: false,
      hasNextButton: true,
      hasBackButton: true,
    },
    {
      name: 'email',
      isValid: false,
      hasNextButton: true,
      hasBackButton: true,
    },
    {
      name: 'processingIncasso',
      isValid: false,
      hasNextButton: false,
      hasBackButton: false,
    },
    {
      name: 'paymentStatus',
      isValid: false,
      hasNextButton: false,
      hasBackButton: false,
    },
    {
      name: 'redirectToPaymentProvider',
      isValid: false,
      hasNextButton: false,
      hasBackButton: false,
    },
  ] as const
  const AVAILABLE_STEPS: DonationFormStep[] = AVAILABLE_STEPS_CONST as any
  type AvailableStepNames = (typeof AVAILABLE_STEPS_CONST)[number]['name'][]

  const MICRODONATION_IDEAL_STEPS: AvailableStepNames = [
    'amount',
    'paymentMethod',
    'minimalContact',
    'redirectToPaymentProvider',
    'paymentStatus',
  ]
  const NORMAL_INCASSO_STEPS: AvailableStepNames = [
    'recurrance',
    'amount',
    'paymentMethod',
    'name',
    'address',
    'account',
    'email',
    'processingIncasso',
    'paymentStatus',
  ]
  const NORMAL_IDEAL_STEPS: AvailableStepNames = [
    'recurrance',
    'amount',
    'paymentMethod',
    'name',
    'email',
    'redirectToPaymentProvider',
    'paymentStatus',
  ]

  // Get steps in order of the names provided
  const getSteps = (names: AvailableStepNames) =>
    AVAILABLE_STEPS.filter((step) => names.includes(step.name)).sort(
      (stepA, stepB) => names.indexOf(stepA.name) - names.indexOf(stepB.name),
    )

  const formTransition = ref()
  const selectedGift = ref()
  const currentFlow = ref<DonationFormStep[]>(
    getSteps(['recurrance', 'amount', 'paymentMethod']),
  )
  const currentStepIndex = ref(0)
  const selectedPeriod = ref()
  const microdonationFlow = ref(false)

  const currentStep = ref<DonationFormStep>(
    currentFlow.value[currentStepIndex.value],
  )
  const amount = ref<DonationAmount>()

  const setMicrodonationFlow = (bool: boolean) => {
    microdonationFlow.value = bool
  }

  const setFlow = () => {
    let steps: DonationFormStep[]
    if (microdonationFlow.value) {
      // Always ideal
      steps = getSteps(MICRODONATION_IDEAL_STEPS)
    } else if (orderStore.paymentMethod === 'incasso') {
      steps = getSteps(NORMAL_INCASSO_STEPS)
    } else {
      steps = getSteps(NORMAL_IDEAL_STEPS)
    }
    currentFlow.value = steps
  }

  const setCurrentStepByName = (name: string) => {
    const newStep = currentFlow.value.find((item) => item.name === name)
    if (newStep) {
      currentStep.value = newStep
      currentStepIndex.value = currentFlow.value.findIndex(
        (item) => item.name === name,
      )
    } else {
      throw new Error('Provided name has no step')
    }
  }

  const setCurrentStepValid = (isValid: boolean) => {
    currentStep.value = { ...currentStep.value, isValid }
  }

  const setSelectedPeriod = (newPeriod: any) => {
    orderStore.setRepetition(newPeriod.repetition)
    orderStore.setSalesforceProduct(newPeriod.product.key)
    orderStore.setCampaignCode(newPeriod.campaignCode)
    orderStore.setDescription(newPeriod.product.title)
    orderStore.setDurationType(newPeriod.product.durationType)
    orderStore.addOrUpdateProduct(
      { ...newPeriod.product, price: amount.value?.amount },
      selectedPeriod.value?.product,
    )
    selectedPeriod.value = newPeriod
  }

  const initializeMicrodonationFlow = (
    periods: any,
    sfCampaign: string | null,
    sfCampaignKeyword?: string | null,
  ) => {
    setMicrodonationFlow(true)
    setFlow()
    setCurrentStepByName(currentFlow.value[0].name)
    setSelectedPeriod(
      periods.find((period: any) => period.repetition === 'one-time'),
    )
    sfCampaign && orderStore.setSfCampaign(sfCampaign)
    sfCampaignKeyword && orderStore.setSfCampaignKeyword(sfCampaignKeyword)
  }

  const stepBack = () => {
    currentStepIndex.value = currentStepIndex.value -= 1
    currentStep.value = currentFlow.value[currentStepIndex.value]
    formTransition.value = 'slide-prev'
  }

  const stepForward = () => {
    currentStepIndex.value = currentStepIndex.value += 1
    currentStep.value = currentFlow.value[currentStepIndex.value]
    formTransition.value = 'slide-next'
  }

  const setAmount = (newAmount: DonationAmount) => {
    amount.value = newAmount
    orderStore.addOrUpdateProduct(
      { ...selectedPeriod.value.product, price: newAmount.amount },
      selectedPeriod.value.product,
    )
    if (
      selectedGift.value?.minimumAmount &&
      amount.value.amount &&
      amount.value.amount < selectedGift.value.minimumAmount
    ) {
      orderStore.removeProduct(selectedGift.value)
      selectedGift.value = null
    }
  }

  const setPaymentMethod = (newMethod: string) => {
    orderStore.setPaymentMethod(newMethod)
    setFlow()
  }

  const setSelectedGift = (newGift: any) => {
    if (newGift.id === 'no-gift') {
      orderStore.removeProduct(selectedGift.value)
      selectedGift.value = undefined
    } else {
      orderStore.addOrUpdateProduct(newGift, selectedGift.value)
      selectedGift.value = newGift
    }
  }

  const setPaymentStatusCode = (newStatusCode: string) => {
    orderStore.setPaymentStatusCode(newStatusCode)
  }

  return {
    amount,
    currentFlow,
    currentStep,
    currentStepIndex,
    formTransition,
    microdonationFlow,
    selectedGift,
    selectedPeriod,
    setAmount,
    setCurrentStepByName,
    setCurrentStepValid,
    setMicrodonationFlow,
    setFlow,
    setPaymentMethod,
    setPaymentStatusCode,
    setSelectedGift,
    setSelectedPeriod,
    initializeMicrodonationFlow,
    stepBack,
    stepForward,
  }
})
